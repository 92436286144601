<template>
 <v-container lighten-5>
     <v-row>
         <v-col cols="auto" md="12">
             <v-form @submit.prevent="generar">
                 <v-card>
 
                     <v-card-title class="text-center" primary-title>
                         <div>
                             <h3 class="headline mb-0 primary--text">Reporte de RIPS</h3>
                             <div>llenar todos los campos antes de generar</div>
                         </div>
                     </v-card-title>
                     <v-card-text>
                         <v-row>
                             <v-col cols="12" sm="12" md="3">
                               <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field required outlined :error="$v.form.fechaDesde.$error" v-model="form.fechaDesde" label="Fecha desde" append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="form.fechaDesde" @input="menu = false" ></v-date-picker>
                                </v-menu>
                              </v-col>
                             <v-col cols="12" sm="12" md="3">
                              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field required outlined :error="$v.form.fechaHasta.$error" v-model="form.fechaHasta" label="Fecha hasta" append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="form.fechaHasta" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                               </v-col>
                             <v-col cols="12" sm="12" md="3">
                                 <v-autocomplete outlined  :error="$v.form.cocoId.$error" v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                     <template v-slot:selection="data">
                                         {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                     </template>
                                     <template v-slot:item="data">
                                         <template v-if="(typeof data.item !== 'object')">
                                             <v-list-item-content v-text="data.item"></v-list-item-content>
                                         </template>
                                         <template v-else>
                                             <v-list-item-content>                                               
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                 <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                             </v-list-item-content>
                                         </template>
                                     </template>
                                 </v-autocomplete>
                             </v-col>
                             <v-col cols="12" sm="12" md="3">
                                 <v-btn text large color="primary" @click="generar">Generar</v-btn>
                             </v-col>
                         </v-row>
                     </v-card-text>
                     <v-divider></v-divider>
                 </v-card>
             </v-form>
         </v-col>
     </v-row>
 </v-container>
 </template>
 
 <script>
 
 
  
 import { saveAs } from 'file-saver';
 
 import {
     required
 }
 from 'vuelidate/lib/validators';
 export default {
     data() {
         return {
             token: this.$cookies.get("token"),
             url: 'reporte/reportes/',
             empresas: [],
             contratos: [],
             menu:null,
             menu2:null,
             form: {
              cocoId: null,
                 fechaDesde: null,
                 fechaHasta: null
             },
         }
     },
     validations: {
         form: {
 
             cocoId: {
                 required,
             },
             fechaDesde: {
                 required,
             },
             fechaHasta: {
                 required,
             }
         }
     },
 
     created: async function () {
         this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
  },
     methods: {
         async generar() {
             this.$v.form.$touch();
             if (!this.$v.form.$error) {
                 try {
                      var ripLB = await this.$apiService.index(this.url + `ripsjson?cocoId=${this.form.cocoId}&fechaDesde=${this.form.fechaDesde}&fechaHasta=${this.form.fechaHasta}`).then(data => data);
                   
                     saveAs(ripLB, "RIPS.json");
                 } catch (error) {
                     console.log(error);
                     this.$swal.fire({
                         title: "Algo sucedio!",
                         text: "Contactar con el administrador.",
                         icon: "error"
                     });
                 }
             }
         },
 
     },
 }
 </script>
 