<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <span class="primary--text display-2">Reportes multiples</span><br>
        </v-col>
        <v-col cols="auto" md="12">
            <v-card>
                <v-card-title>
                    <span class="black--text headline"></span>
                    <div class="flex-grow-1"></div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-btn class="mx-2" tile color="primary"  @click="agregarReporte">
                                agregar reporte
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-row v-for="(item,i) in form" :key="i">
                                <v-col cols="12" sm="12" md="10">
                                    <v-autocomplete outlined dense required v-model="item.coreId" :loading="item.reportes.isLoading" :search-input.sync="item.reportes.search" hide-no-data hide-selected no-filter :items="item.reportes.reportes" @keypress.enter="searchReport(item)" item-text="coreNombre" item-value="coreId" item-color="primary" label="Reporte" clearable @change="getDatosReporte(item)"></v-autocomplete>
                                </v-col>
                                <v-col cols="auto" md="2" v-if="i > 0">
                                    <v-btn text icon color="error" @click="eliminarReporte(item)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-row v-if="item.tblConfReportesparametros.length >0">
                                        <v-col cols="12" sm="12" md="4" v-for="(items,i) in item.tblConfReportesparametros" :key="i">
                                            <label :for="items.corpId+items.corpNombre">{{items.corpLabel}}</label>
                                            <template v-if="items.tipo.comaNombrecorto == 'TEXT'">
                                                <v-text-field :id="items.corpId+items.corpNombre" outlined dense v-model="items.Valor"></v-text-field>
                                            </template>
                                            <template v-if="items.tipo.comaNombrecorto == 'TEXTAREA'">
                                                <v-textarea :id="items.corpId+items.corpNombre" outlined dense v-model="items.Valor"></v-textarea>
                                            </template>
                                            <template v-if="items.tipo.comaNombrecorto == 'LISTA'">
                                                <v-autocomplete v-if="items.corpConsulta && listasParametros.length>0" :id="items.corpId+items.corpNombre" outlined dense v-model="items.Valor" :items="listasParametros.find(e => e.corpId ==items.corpId).lista" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                                                <v-autocomplete v-else :id="items.corpId+items.corpNombre" outlined dense v-model="items.Valor" :items="getValues(items.corpValor)" item-text="texts" item-value="values" item-color="primary" clearable></v-autocomplete>
                                            </template>
                                            <template v-if="items.tipo.comaNombrecorto == 'SI/NO'">
                                                <v-switch :id="items.corpId+items.corpNombre" v-model="items.Valor" :true-value="items.corpValor.split(';')[0]" :false-value="items.corpValor.split(';')[1]"></v-switch>
                                            </template>
                                            <template v-if="items.tipo.comaNombrecorto == 'FECHA'">
                                                <v-menu v-model="items.menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field required outlined dense v-model="items.Valor" append-icon="event" readonly v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker locale="ES" v-model="items.Valor" @input="items.menu = false"></v-date-picker>
                                                </v-menu>
                                            </template>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-alert text prominent dense type="info">
                                                Este reporte no cuenta con parametros, puedes generarlo.
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" :loading="loading" dark @click="generar('cvs')" v-if="form.length > 0">Guardar en cvs</v-btn>
                    <v-btn color="primary darken-1" :loading="loading" dark @click="generar('excel')" v-if="form.length > 0">Guardar en excel</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { saveAs } from 'file-saver';
import converter from 'json-2-csv';
import JSZip from 'jszip';
import generales from '../../mixins/generales.js';
import {export_json_to_excel} from '@/vendor/Export2Excel';
export default {
    mixins: [generales],
    data() {
        return {
            url: '/reporte/reportes/',
            loading: false,
            form:[],
            listasParametros: [],
        }
    },
    created: async function () {
      
    },
    methods: {
        async searchReport(item) {
            item.reportes.isLoading = true;
            if (item.reportes.search.length > 0 && item.reportes.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("reporte/reportes/buscar?coreNombre=" + item.reportes.search.toUpperCase()).then(data => {
                    item.reportes.isLoading = false;
                    item.reportes.reportes = data;
                });
            }
        },
        async getDatosReporte(item) {
            let reporte = item.reportes.reportes.find(e => e.coreId == item.coreId);
            let tblConfReportesparametros = reporte.tblConfReportesparametros;
            if (!tblConfReportesparametros) {
                tblConfReportesparametros = [];
            } else {
                tblConfReportesparametros.forEach(async o => {
                    o.Valor = null;
                    o.menu = false;
                    if (o.corpConsulta) {
                    let lista = await this.$apiService.create(this.url + "consultar/", {consulta: o.corpConsulta}).then(data => data);
                    this.listasParametros.push({corpId: o.corpId,lista: lista});
                    }
                });
                this.form.find(e => e.coreId == reporte.coreId).coreConsulta = reporte.coreConsulta;
                this.form.find(e => e.coreId == reporte.coreId).coreNombre =reporte.coreNombre;
                this.form.find(e => e.coreId == reporte.coreId).tblConfReportesparametros = tblConfReportesparametros;
            }
        },
        async generar(tipo) {
                try {
                    this.loading = true;
                    let data = await this.$apiService.create(this.url + "ejecutarMultiple/", this.form).then(data => data);
                    let zip = new JSZip();
                    for (let reporte of data) {
                        if(tipo == "cvs"){
                            let csvrip = await converter.json2csvAsync(reporte.result, { 
                                prependHeader: false ,
                                emptyFieldValue:true
                            });
                            zip.file(reporte.coreNombre+".txt", csvrip);
                        }else{
                            
                                let head = [{sheet:"Page1",data:Object.keys(reporte.result[0])}];
                                let data = [{sheet:"Page1",data:reporte.result.map(item => {
                                    return Object.values(item)
                                })}];
                                let excelrip =  await export_json_to_excel({
                                    header: head, //Header Required
                                    data, //Specific data Required
                                    filename: this.form.coreNombre, //Optional
                                    autoWidth: true, //Optional
                                    bookType: 'xlsx', //Optional
                                    download:false
                                })
                                zip.file(reporte.coreNombre+".xlsx", excelrip);
                        }
                    } 
                    zip.generateAsync({ type: "blob" }).then(function (blob) {
                        saveAs(blob, "REPORTES_MULTIPLES.zip");
                    });
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "Contactar con el administrador.",
                        icon: "error"
                    });
                }
        },
        agregarReporte() {
            this.form.push({
                coreId: null,
                coreNombre: null,
                coreConsulta: '',
                tblConfReportesparametros: [],
                reportes: {
                    search: null,
                    isLoading: false,
                    reportes: []
                },
            });
        },
        eliminarReporte(item) {
            this.form = this.form.filter(e =>this.form.indexOf(e) !=this.form.indexOf(item));
        },
    },
}
</script>
