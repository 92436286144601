<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="generar">
                <v-card>

                    <v-card-title class="text-center" primary-title>
                        <div>
                            <h3 class="headline mb-0 primary--text">Reporte de RIPS PARA LABORATORIOS</h3>
                            <div>llenar todos los campos antes de generar</div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete outlined v-model="form.TipoMes" :error="$v.form.TipoMes.$error" :items="tipomes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Mes" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete outlined v-model="form.TipoAnio" :error="$v.form.TipoAnio.$error" :items="tipoanio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Año" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete outlined  :error="$v.form.cocoId.$error" v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-btn text large color="primary" @click="generar">Generar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                </v-card>
            </v-form>
        </v-col>
    </v-row>
</v-container>
</template>

<script>


 
import { saveAs } from 'file-saver';

import {
    required
}
from 'vuelidate/lib/validators';
import converter from 'json-2-csv';
import JSZip from 'jszip';

export default {
    data() {
        return {
            token: this.$cookies.get("token"),
            url: 'reporte/reportes/',
            empresas: [],

            contratos: [],
            tipomes: [],
            tipoanio: [],
            salario: 0,
            form: {
                coemId: null,
                TipoMes: null,
                TipoAnio: null
            },
        }
    },
    validations: {
        form: {

            cocoId: {
                required,
            },
            TipoMes: {
                required,
            },
            TipoAnio: {
                required,
            }
        }
    },

    created: async function () {
        this.$store.commit('setCargaDatos', true);
        this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
        this.empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
        this.tipomes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMES").then(data => data);
        this.tipoanio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOANIO").then(data => data);

        this.$store.commit('setCargaDatos', false);
    },
    methods: {
        async generar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    var anio = await this.$apiService.index("sistema/maestra/view/" + this.form.TipoAnio).then(data => data);
                    var mes = await this.$apiService.index("sistema/maestra/view/" + this.form.TipoMes).then(data => data);
                    var ripLB = await this.$apiService.index(this.url + `riplaboratorio/${anio.comaNombrecorto}/${mes.comaValor}/${this.form.cocoId}`).then(data => data);
                  
                    const csvripLB = await converter.json2csvAsync(ripLB, { prependHeader: false });
                    const zip = new JSZip();
                    zip.file("ripsLB.txt", csvripLB);
                    zip.generateAsync({ type: "blob" }).then(function (blob) {
                        saveAs(blob, "RIPS.zip");
                    });

                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "Contactar con el administrador.",
                        icon: "error"
                    });
                }

            }
        },

    },
}
</script>
